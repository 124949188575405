<template>
    <v-form @submit.prevent="search">
        <v-row dense>
            <v-col cols="12" lg="2">
                <v-text-field
                    v-model="product_reference"
                    label="Código"
                    placeholder="MB0000"
                    color="primary"
                    outlined
                    rounded
                    dense
                    flat
                />
            </v-col>
            <v-col>
                <v-btn
                    class="primary white--text"
                    type="submit"
                    rounded
                    icon
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col align="end">
                <v-dialog max-width="600" v-model="temporaryModal">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" class="primary mr-2" rounded dense>
                            Produto temporário
                            <v-icon class="ml-2" small>fas fa-clock</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span>Produto temporário</span>
                            <v-spacer />
                            <v-btn @click="() => temporaryModal = false" icon>
                                <v-icon color="white">mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <TemporaryProductsForm
                            @success="e => $emit('addTemporaryProduct', e)"
                            ref="temporaryForm"
                        />

                        <v-card-actions class="justify-end">
                            <v-btn @click="addTemporary" color="primary" rounded>Adicionar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-btn
                    rounded
                    outlined
                    color="primary"
                    @click="() => $emit('openAdvancedFilter')"
                >
                    Filtro avançado
                    <v-icon right>mdi-filter</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
    export default {
        components: {
            TemporaryProductsForm: () => import("@/components/stock/products/TemporaryProductsForm"),
        },
        data: () => ({
            product_reference: null,
            temporaryModal: false,
        }),
        methods: {
            addTemporary(){
                this.$refs.temporaryForm.createTemporary()
                this.temporaryModal = false
            },
            search(){
                this.$emit('searchByReference', this.product_reference)
                this.product_reference = null
            }
        }
    }
</script>